<template>
  <div class="tables">
    <div class="btn">
      <el-button icon="el-icon-back" size="mini" round @click="$router.go(-1)"
        >返回</el-button
      >
      <el-button icon="el-icon-finished" size="mini" round @click="exportExcel"
        >一键导出</el-button
      >
    </div>
    <ListData :tableData="tableData" @RefreshData="RefreshData" />
  </div>
</template>

<script>
import ListData from "../../components/ListData";
export default {
  data() {
    return {
      tableData: []
    };
  },
  components: {
    ListData
  },
  methods: {
    RefreshData() {
      this.$common.getInterface("/api/v1.projects/getLists", {}, this, res => {
        // console.log(res);
        this.tableData = res.data.data;
      });
    },
    //导出的方法
    exportExcel() {
      require.ensure([], () => {
        const { export_json_to_excel } = require("../../excel/Export2Excel");
        const tHeader = [
          "序号",
          "客户名称",
          "难度",
          "项目名称",
          "组长",
          "画师",
          // "预期进度",
          "实际进度",
          "项目状态",
          "备注",
          // "开始时间",
          "草稿时间",
          "色稿时间",
          "细化时间",
          "结束时间"
        ];
        // 上面设置Excel的表格第一行的标题
        const filterVal = [
          "id",
          "customer_name",
          "level_id",
          "title",
          "headman_name",
          "workers",
          "progress",
          "progress_per",
          "status",
          "descr",
          "date_start",
          "date_start_draft",
          "date_start_color",
          "date_start_refine",
          "date_end"
        ];
        // 上面的filterVal中的元素是tableData里对象的属性名
        const list = this.tableData; //把data里的tableData存到list
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, "鬼画符项目列表");
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          if (j == "workers") {
            let workersListName = [];
            v[j].forEach(item => {
              workersListName.push(item.name);
            });
            return workersListName.join(",");
          } else if (j == "level_id") {
            let levels = ["C", "B", "A", "S"];
            return levels[v[j] - 1];
          } else if (j == "status") {
            let status = ["待开始", "进行中", "暂停", "完成"];
            return status[v[j]];
          } else if (j == "date_start_draft") {
            return v.date_start_draft + "~" + v.date_end_draft;
          } else if (j == "date_start_color") {
            return v.date_start_color + "~" + v.date_end_color;
          } else if (j == "date_start_refine") {
            return v.date_start_refine + "~" + v.date_start_refine;
          } else {
            return v[j];
          }
        })
      );
    }
  },
  mounted() {
    this.tableData = this.$route.params.tableData;
  }
};
</script>

<style lang="less" scoped>
.tables {
  padding: 0 30px 50px;
  .btn {
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
  }
}
</style>
